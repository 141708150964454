<template>
<!-- <div class="d-flex d-md-none flex-wrap align-items-center justify-content-center justify-content-md-between py-2">
    <a href="#" class="d-flex align-items-center col-4 mb-2 animated-arrow"  onclick="openNav()">
        <span></span>
    </a>
    <div class="col-4 justify-content-center text-center"><Logo /></div>
    <div class="col-4 text-end">
        <button type="button" class="btn btn-sm btn-ondaq-dark btn-open-app"><img src="@/assets/images/icons/add-user.png">Open App</button>
    </div>
</div> -->

<div class="d-flex d-md-none flex-wrap align-items-center justify-content-center justify-content-md-between py-2">
    <a href="#" class="col-4 animated-arrow" v-on:click.prevent="openNav">
        <span></span>
    </a>
    <div class="col-4 justify-content-center"><Logo /></div>
    <div class="col-4 text-center ps-4">
        <!-- <div class="float-end user-dropdown pe-4" v-if="!$storage.getStorageSync('token')">
            <a href="#" class="col-4 animated-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                <span></span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <li><button v-if="!$storage.getStorageSync('token')" type="button" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#loginModal"><fa class="dropdown-icon" icon="sign-in-alt" /> Sign In</button></li>
                <li><button v-if="!$storage.getStorageSync('token')" type="button" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#signupModal"><fa class="dropdown-icon" icon="user-plus" /> Sign Up</button></li>
            </ul>
        </div> -->

        <NavAfterLogin />
            
        <router-link to="/list-your-business" v-if="!$storage.getStorageSync('token')" style="font-weight: 500;font-size: 14px">Ondaq <p class="mb-0" style="margin-top: -5px">Business</p></router-link>
    </div>
</div>
<NavMain type="mobile" />
</template>

<script setup>
import Logo from '@/components/common/header/Logo'
import NavAfterLogin from '@/components/common/header/NavAfterLogin'
import NavMain from '@/components/common/header/NavMain'
function openNav() {
    document.getElementById("menuSidepanel").style.width = "100%";
}
</script>