<template>
    <ul v-if="type === 'web'" class="nav col-12 col-md-5 col-lg-6 mb-2 justify-content-lg-end justify-content-center mb-md-0 centered-nav ">
        <!-- <li><router-link :to="{name:'/'}" class="nav-link px-2 mx-2 " v-on:click="isActive = !isActive" v-bind:class="{ active: isActive }" >Home</router-link></li> -->
        <!-- <li><router-link :to="{name:'Marketing'}" class="nav-link px-2 mx-2">Marketing</router-link></li> -->
        <li><router-link :to="{name:'OndaqBusiness'}" class="nav-link px-2 mx-2 ondaq_business"  >OnDaQ Business</router-link></li>
        <li><router-link :to="{name:'HowItWorks'}" class="nav-link px-2 mx-2" >How it Works</router-link></li>
        <li><router-link :to="{name:'Pricing'}" class="nav-link px-2 mx-2" >Pricing</router-link></li>
        <li><a :href="helpCenterURL" class="nav-link px-2 mx-2" >Help?</a></li>
    </ul>
    <div class="mobile-menu" v-if="type === 'mobile'">   
        <div id="menuSidepanel" class="menu-sidebar">
            <div class="m-nav">
                <div class="text-center py-1 border-bottom pe-4"><Logo /></div>
                <!-- <a href="javascript:void(0)" class="closebtn" v-on:click.prevent="closeNav">×</a> -->
                <div class="login-menu" v-if="!$storage.getStorageSync('token')">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#loginModal" v-on:click.prevent="closeNav"><fa class="dropdown-icon" icon="sign-in-alt" />Sign In</a>
                    <a href="#" data-bs-toggle="modal" data-bs-target="#signupModal" v-on:click.prevent="closeNav"><fa class="dropdown-icon" icon="user-plus" />Sign Up</a>
                </div>
                <div class="login-menu" v-if="$storage.getStorageSync('token') && $storage.getStorageSync('business').id == ''">
                    <router-link to="/list-your-business"><fa icon="plus" style="font-size: 14px;"/> List My Business</router-link>
                </div>
                <div class="login-menu" v-if="$storage.getStorageSync('token') && $storage.getStorageSync('business').id != ''">
                    <router-link :to="{name:'BusinessDashboard'}" target='_blank' class="text-purple"> Go to the dashboard</router-link>
                </div>
                <router-link :to="{name:'OndaqBusiness'}" class="mt-3 text-purple" >OnDaQ Business</router-link>
                <router-link :to="{name:'HowItWorks'}">How it Works</router-link>
                <router-link :to="{name:'Pricing'}">Pricing</router-link>
                <a :href="helpCenterURL" class="text-purple" >Need Help?</a>
                <router-link to="/">Send a Feedback</router-link>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import Logo from '@/components/common/header/Logo'
import $ from 'jquery'
defineProps({ 
    type:String
});

const helpCenterURL = ref(process.env.VUE_APP_ZENDESK_SUB_DOMAIN)
onMounted(() => {
    $('#menuSidepanel').click(function(e){
        var posX = e.pageX - $(this).position().left;
        var width = $('.m-nav').width();
        if (posX > width) {
            document.getElementById("menuSidepanel").style.width = "0";
        }
    });
});

function closeNav() {
    document.getElementById("menuSidepanel").style.width = "0";
}
</script>
<style scoped>
.ondaq_business{
    font-weight: bold;
    color: #710599;
}
.menu-sidebar {
    transition: none !important;
}
@media (max-width: 575px){
    .menu-sidebar a {
        font-size: 19px;
    }
    .m-nav {
        width: 314px;
    }
}
</style>