<template>
    <div v-if="$storage.getStorageSync('token')" class="float-end user-dropdown">
        <button type="button" class="position-relative border-0 bg-transparent" data-bs-toggle="dropdown" aria-expanded="false">
            <img v-if="$storage.getStorageSync('user').picture" alt="" :src="$storage.getStorageSync('user').picture" class="img-fluid small-profile-pic">
            <img v-if="!$storage.getStorageSync('user').picture" alt="" :src="assetUrl('profile/profile.svg')" class="img-fluid small-profile-pic">
            <span class="online"></span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <li v-if="$storage.getStorageSync('business').id > 0 && $storage.getStorageSync('role') == 'business' "><router-link :to="{name:'BusinessDashboard'}" target='_blank' class="dropdown-item text-purple"><fa class="dropdown-icon" :icon="['far','gem']" /> Go to Business Dashboard</router-link></li>
            <li v-if="$storage.getStorageSync('role') == 'professional' "><router-link :to="{name:'BusinessDashboard'}" target='_blank' class="dropdown-item text-purple"><fa class="dropdown-icon" :icon="['far','gem']" /> Go to Professional Dashboard</router-link></li>
            <li><router-link :to="{name:'EditProfile'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','user']" />Dashboard</router-link></li>
            <li><router-link :to="{name:'Chat'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','comments']" />Messages</router-link></li>
            <li><router-link :to="{name:'userFavourites'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','heart']" />Favorites</router-link></li>
            <li><a :href="helpCenterURL" class="dropdown-item"><fa class="dropdown-icon" icon="question-circle" />Help &amp; Support</a></li>
            <li><a class="dropdown-item" href="#" v-on:click="logout"><fa class="dropdown-icon" icon="sign-out-alt" /> Sign Out</a></li>
        </ul>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import { useAuth } from '@/composables/Auth';
const { logout } = useAuth();
const helpCenterURL = ref(process.env.VUE_APP_ZENDESK_SUB_DOMAIN)
</script>