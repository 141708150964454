<template>
    <!-- Register Modal -->
    <div class="modal fade" id="signupModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="signupModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="signupModalLabel"><img :src="assetUrl('logo-small.png')" alt="OnDaQ Logo" class="img-fluid me-2" />Let's Get You Started</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label class="form-label">First Name</label>
                          <input type="text" class="form-control" name="fname" v-model="signup_.first_name" placeholder="John" :class="[v$.first_name.$error ? 'border-danger': '']">
                          <!-- <div v-if="v$.signup_.first_name.$error" class="form-error-msg-labeled">
                            <span v-if="this.v$.signup_.first_name.$errors[0].$validator == 'required'">First name is required</span>
                          </div> -->
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label class="form-label">Last Name</label>
                          <input type="text" class="form-control" name="lname" v-model="signup_.last_name" placeholder="Doe" :class="[v$.last_name.$error ? 'border-danger': '']">
                          <!-- <div v-if="v$.signup_.last_name.$error" class="form-error-msg-labeled">
                            <span v-if="this.v$.signup_.last_name.$errors[0].$validator == 'required'">Last name is required</span>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="form-label">Email</label>
                          <input type="email" class="form-control" :class="[v$.email.$error ? 'border-danger': '']" name="remail" v-model="signup_.email" placeholder="username@example.com">
                          <!-- <div v-if="v$.signup_.email.$error" class="form-error-msg-labeled">
                            <span v-if="this.v$.signup_.email.$errors[0].$validator == 'email'">Invalid Email</span>
                            <span v-if="this.v$.signup_.email.$errors[0].$validator == 'required'">Email is required</span>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label class="form-label">Password</label>
                          <input type="password" class="form-control" :class="[v$.password.$error ? 'border-danger': '']" name="rpassword" v-model="signup_.password" placeholder="******">
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label class="form-label">Confirm Password</label>
                          <input type="password" class="form-control" :class="[v$.c_password.$error ? 'border-danger': '']" name="cpassword" v-model="signup_.c_password" placeholder="******">
                          <div v-if="v$.c_password.$error && v$.c_password.$errors[0].$validator == 'mustBeEqual'" class="form-error-msg-labeled">
                            <span>Password don't match</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label class="form-label">Gender</label>
                          <select class="form-select" :class="[v$.gender.$error ? 'border-danger': '']" v-model="signup_.gender">
                            <option value="">Choose your Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </select>
                          <!-- <div v-if="v$.signup_.gender.$error" class="form-error-msg-labeled">
                            <span v-if="this.v$.signup_.gender.$errors[0].$validator == 'required'">Choose gender</span>
                          </div> -->
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label class="form-label">Phone</label>
                          <PhoneInput v-model="signup_.phone" @country-changed="updateCountryCode" :class='[v$.phone.$error && "border-danger"]' />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 my-1">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-model="signup_.TandC" id="TermAndCond">
                          <label class="form-check-label" for="TermAndCond">By clicking create account, it means you agree to Ondaq’s <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a></label>
                          <div v-if="v$.TandC.$error" class="form-error-msg">
                            <span v-if="v$.TandC.$errors[0].$validator == 'isTrue'">Terms is required</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-grid gap-2 mt-3">
                        <button v-on:click="signup" :disabled="reqProcessing" class="btn btn-common"><span v-if="reqProcessing== false">Create Account</span> <span v-if="reqProcessing">Processing... </span></button>
                    </div>
                    <div class="text-center mt-3 text-dark text-16">Already have account? <a data-bs-dismiss="modal" href="#" @click.prevent="openModal('#loginModal')">Sign In Now!</a></div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Register Modal -->
</template>
<script setup>
import { ref } from 'vue';
import PhoneInput from '@/components/globals/PhoneInput'
import $ from 'jquery'

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import axios from 'axios'
import { useStorage } from "vue3-storage";
import { useToast } from "vue-toastification";

const toast = useToast();
const storage = useStorage()

const reqProcessing = ref(false)
const isTrue = (value) => value===false?false:true
const mustBeEqual = (value) => value == signup_.value.password
const signup_ = ref({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    c_password:'',
    gender:'',
    phone:'',
    dialCode:1,
    TandC:false
})
const rules = {
    first_name: { required },
    last_name: { required },
    email: { required, email },
    password: { required },
    c_password: { required, mustBeEqual},
    gender:{required},
    phone:{required},
    TandC:{isTrue}
}
const v$ = useVuelidate(rules, signup_)

const signup = () => {
    v$.value.$touch()
    if (!v$.value.$error) {
        // console.log(signup_.value)
        reqProcessing.value = true
        axios.post('signup',signup_.value).then(function (response) {
            if (response.data.status == 'success') {
                storage.setStorageSync("user",response.data.data.user)
                storage.setStorageSync("token",response.data.data.token)
                storage.setStorageSync("business",response.data.data.business)
                location.reload()
            }else{
              reqProcessing.value = false
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status == 422) {
                    toast.error(error.response.data.message);
                }
            }
            reqProcessing.value = false
        });
    }
}

function updateCountryCode(data){
    if (data.dialCode) {
        signup_.value.dialCode = data.dialCode
    }
}
function openModal(id){
    $(id).modal('show')
}
</script>
<style scoped>
    .form-control{
      height: 45px;
      background-color:#f5faff;
    }
    .modal-title{
      font-size:22px;
    }
    .btn-common{
        padding-top: 0.585rem;
        padding-bottom: 0.585rem;
    }
    .modal .form-label, .modal .form-check label{
      font-size: 16px;
    }
    @media (max-width: 575px)
    {
      .modal{
        --bs-modal-margin: 0px;
      }
    }
</style>