<template>
  <footer class="pt-4 pt-md-5 border-top pb-0">
    <div class="container">
      <div class="row">
          <div class="col-6 col-md">
              <h4 class="text-light">About</h4>
              <ul class="list-unstyled text-small">
                  <li class="mb-1"><router-link :to="{name:'About'}" class="link-light text-decoration-none fw-light">About Us</router-link></li>
                  <li class="mb-1"><a class="link-light text-decoration-none fw-light" href="#">Our Blog</a></li>
                  <li class="mb-1"><a href="#" class="link-light text-decoration-none fw-light">Terms Of Services</a></li>
                  <!-- <li class="mb-1"><router-link :to="{name:'TermsofService'}" class="link-light text-decoration-none fw-light">Terms Of Services</router-link></li> -->
                  <!-- <li class="mb-1"><router-link :to="{name:'PrivacyPolicy'}" class="link-light text-decoration-none fw-light">Privacy Policy</router-link></li> -->
                  <li class="mb-1"><a href="#" class="link-light text-decoration-none fw-light">Privacy Policy</a></li>
                  <li class="mb-1"><a class="link-light text-decoration-none fw-light" href="#">Cookies Policy</a></li>
              </ul>
          </div>
          <div class="col-6 col-md">
              <h4 class="text-light">Ondaq Help</h4>
              <ul class="list-unstyled text-small">
                  <li class="mb-1"><a :href="helpCenterURL" class="link-light text-decoration-none fw-light" >Help &amp; Support</a></li>
                  <li class="mb-1"><a class="link-light text-decoration-none fw-light" href="#">FAQ's</a></li>
                  <li class="mb-1"><router-link :to="{name:'HowItWorks'}" class="link-light text-decoration-none fw-light">How It Works</router-link></li>
              </ul>
          </div>
          <div class="col-6 col-md">
              <h4 class="text-light">Ondaq Business</h4>
              <ul class="list-unstyled text-small">
                  <li class="mb-1"><router-link :to="{name:'AddBusiness'}" class="link-light text-decoration-none fw-light">List Your Business</router-link></li>
                  <li class="mb-1"><router-link :to="{name:'Pricing'}" class="link-light text-decoration-none fw-light">Pricing</router-link></li>
              </ul>
          </div>
          <div class="col-6 col-md">
              <h4 class="text-light">How Can We Serve You?</h4>
              <ul class="list-unstyled text-small">
                  <li class="mb-1"><a class="link-light text-decoration-none fw-light" href="#">Send A Feedback</a></li>
              </ul>
          </div>
      </div>
      <div class="row">
        <div class="col-12 py-2 center-footer">
          <p class="float-sm-end m-0">Language<a class="ms-4 link-light" href="#">English</a></p>
          <p class="m-0">
            <span class="me-4">Connect With Us:</span> 
            <a href="#" class="text-white me-4"><fa :icon="['fab','facebook-f']" /></a>
            <a href="#" class="text-white me-4"><fa :icon="['fab','twitter']" /></a>
            <a href="#" class="text-white me-4"><fa :icon="['fab','linkedin-in']" /></a>
            <a href="#" class="text-white me-4"><fa :icon="['fab','youtube']" /></a>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 py-2 text-center text-light footer-bottom">
          <p class="m-0">&copy; 2022 OnDaQ, Inc. All rights reserved</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    name: "TheFooter",
    data(){
        return {
            helpCenterURL : process.env.VUE_APP_ZENDESK_SUB_DOMAIN
        }
    }
}
</script>