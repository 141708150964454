<template>
    <vue-tel-input :onlyCountries="['US','PK']" :defaultCountry="dialCode ? dialCode:1" v-mask="'(###) ### - ####'" :inputOptions="{type:'tel',placeholder:'(000) 000 - 0000'}" :dropdownOptions="{showDialCodeInList:true,showFlags:true,showDialCodeInSelection:true}" :autoFormat="false" mode="international"></vue-tel-input>
</template>

<script>
import {mask} from 'vue-the-mask'
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
    name: "PhoneInput",
    props: {
        classes: {
            type: [Number, String, Array],
            default: '',
        },
        dialCode: {
            type: [Number],
            default: null,
        }
    },
    components: {VueTelInput},
    directives: {mask}
}
</script>
<style scoped>
  .vti__dropdown-list{
      width: 221px !important;
      overflow: hidden;
  }
  .vti__dropdown-item{
    padding: 4px 7px !important;
  }
  .vue-tel-input{
      font-size: 0.9375rem;
      line-height: 1.6;
      color: #605e7e;
      height: 39px;
      background-clip: padding-box;
      box-shadow: none;
      border: 1px solid #e8ebf3;
      border-radius: 3px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
</style>