<template>
<!-- Login Modal -->
<div class="modal fade" id="loginModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="loginModalLabel"><img :src="assetUrl('logo-small.png')" alt="OnDaQ Logo" class="img-fluid me-2" />Welcome back!</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6 offset-md-3 col-12 mb-2">
                        <GoogleLogin :buttonConfig="{size:'large',text:'continue_with'}" :callback="googleLogin" prompt />
                    </div>
                </div>
                <hr class="divider">
                <div class="form-group">
                    <label class="form-label">Email</label>
                    <input type="text" class="form-control" :class="[v$.email.$error ? 'border-danger': '']" name="email" v-model="login_.email" placeholder="username@example.com">
                    <!-- <div v-if="v$.login_.email.$error && this.v$.login_.email.$errors[0].$validator == 'email'" class="form-error-msg-labeled">
                        <span v-if="this.v$.login_.email.$errors[0].$validator == 'email'">Invalid Email</span>
                    </div> -->
                </div>
                <div class="form-group">
                    <label class="form-label">Password</label>
                    <div class="input-group mb-3">
                        <input :type="passwordType" class="form-control" :class="[v$.password.$error ? 'border-danger': '']" name="password" v-model="login_.password" placeholder="******">
                        <span class="input-group-text"><fa :icon="['far','eye']" v-show="passwordType=='text'" @click="switchVisibility" /><fa @click="switchVisibility" v-show="passwordType=='password'" :icon="['far','eye-slash']" /></span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="remember_me">
                            <label class="form-check-label" for="remember_me">Remember me</label>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6">
                        <a data-bs-dismiss="modal" @click.prevent="openModal('#ForgotPasswordModal')" href="#" class="form-text text-dark float-end"><small class="text-14">Forgot password?</small></a>
                    </div>
                </div>
                <div class="d-grid gap-2 mt-3">
                    <button v-on:click="login" :disabled="reqProcessing" class="btn btn-common" ><span v-if="reqProcessing== false">Sign In</span> <span v-if="reqProcessing">Processing... </span></button>
                    <p class="text-12 my-3">By clicking sign in and continue with Facebook or Google, it means you agree to our <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a> .</p>
                </div>
                <div class="text-center mt-3 text-dark text-16">Don't have account yet?</div>
                <div class="d-grid gap-2 mt-3">
                    <button type="button" data-bs-dismiss="modal" class="btn outling-button" @click.prevent="openModal('#signupModal')">Sign Up</button>
                </div>
            </div>
        </div>
    </div>
</div>
    <!-- End Login Modal -->
</template>
<script setup>
import { ref } from 'vue';
import axios from 'axios'
import $ from 'jquery'
import { useStorage } from "vue3-storage";
import { decodeCredential } from 'vue3-google-login'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from "vue-toastification";
const toast = useToast();
const storage = useStorage()

const reqProcessing = ref(false)
const passwordType = ref('password')
// Add form validation
const login_ = ref({
    email:'',
    password:'',
    req_from:'web-form',
    google_id: '',
    name:'',
    first_name:'',
    last_name:'',
    picture:''
})

const rules = {
    email: { required },
    password: { required }
}
const v$ = useVuelidate(rules, login_)

function googleLogin(response){
    reqProcessing.value = true
    const userData = decodeCredential(response.credential)
    login_.value.req_from   = 'google'
    login_.value.first_name = userData.given_name
    login_.value.last_name  = userData.family_name
    login_.value.name       = userData.name
    login_.value.email      = userData.email
    login_.value.google_id  = userData.sub
    login_.value.picture  = userData.picture
    
    // console.log("Handle the userData", userData, login_.value)
    if(userData.email){
        loginProcess()
    }else{
        toast.error("Sorry! We found a problem in google please use email and password for login.");
    }
}

const login = () => {
    login_.value.req_from = 'web-form'
    v$.value.$touch()
    if (!v$.value.$error) {
        loginProcess()
    }
}
function loginProcess(){
    reqProcessing.value = true
    axios.post('login',login_.value).then(function (response) {
        if (response.data.status == 'success') {
            //console.log(response.data.data)
            storage.setStorageSync("user",response.data.data.user)
            storage.setStorageSync("token",response.data.data.token)
            storage.setStorageSync("business",response.data.data.business)
            storage.setStorageSync("role",response.data.data.role)
            storage.setStorageSync("employee_id",response.data.data.employeeId)
            // thiss.$router.push("/")
            location.reload()
        }else{
            reqProcessing.value = false
        }
    })
    .catch(error => {
        if (error.response) {
            if (error.response.status == 422) {
                toast.error(error.response.data.message);
            }else if (error.response.status == 401) {
                toast.error(error.response.data.message,{position: "top-right"});
            }
        }
        reqProcessing.value = false
    });
}
function openModal(id){
    $(id).modal('show')
}
function switchVisibility() {
    passwordType.value = passwordType.value === "password" ? "text" : "password";
}
</script>
<style scoped>  
    .form-control{
      height: 45px;
      background-color:#f5faff;
    }
    .modal-title{
      font-size:22px;
    }
    .btn-common{
        padding-top: 0.585rem;
        padding-bottom: 0.585rem;
    }
    .modal .form-label, .modal .form-check label{
      font-size: 16px;
    }
    @media (max-width: 575px)
    {
      .modal{
        --bs-modal-margin: 0px;
      }
    }
</style>