<template>
    <div class="alert alert-success alert-dismissible fade show text-center mb-0 text-white border-dark rounded-0 py-2 d-none d-md-block" role="alert" v-if="props.status && broadcast.show_info_text == 1" style="font-size: 14px;background-color: #000000">
        <fa class="dropdown-icon me-0 text-white" :icon="['far','gem']" />
        {{broadcast.info_text}}
        <router-link :to="broadcast.info_url" class="alert-link ms-3 text-white border rounded p-1" v-if="broadcast.show_info_url == 1">{{broadcast.info_button_text}}</router-link>
        <button type="button" class="btn-close bg-white opacity-100 p-1 me-3" data-bs-dismiss="alert" aria-label="Close" style="font-size: 12px;margin-top: 9px"></button>
    </div>
</template>

<script setup>
import {ref,onMounted} from 'vue'
import axios from 'axios'

const broadcast = ref({})
const props = defineProps({ 
    status:Boolean
});
onMounted(() => {
    if(props.status == true){
        broadcastInfo()
    }
})
function broadcastInfo(){
    axios.get('settings').then(function (response) {
        if (response.data.status == 'success') {
            broadcast.value.show_info_text = response.data.data[1].value
            broadcast.value.info_text = response.data.data[2].value
            broadcast.value.show_info_url = response.data.data[3].value
            broadcast.value.info_button_text = response.data.data[4].value
            broadcast.value.info_url = response.data.data[5].value
        }
    });
}
</script>