<template>
    <!-- Forgot Password Modal -->
    <div class="modal fade" id="ForgotPasswordModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="ForgotPasswordModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="ForgotPasswordModalLabel">Forgot Password</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="resetPassword">
                      <div class="form-group">
                        <label class="form-label">Email</label>
                        <input type="email" v-model="resetPass_.email"  class="form-control"  placeholder="username@example.com">
                        <div v-if="v$.email.$error" class="form-error-msg-labeled">
                          <span v-if="v$.email.$errors[0].$validator == 'email'">Invalid Email</span>
                          <span v-if="v$.email.$errors[0].$validator == 'required'">Email is required</span>
                        </div>
                      </div>

                      <div class="d-grid gap-2 mt-3">
                        <input value="Send Reset E-mail" name="submit" class="btn btn-common" type="submit">
                      </div>
                      <div class="text-center mt-3 text-dark text-14">Forget it, Send me back to the <a data-bs-dismiss="modal" @click.prevent="openModal('#loginModal')" href="#">Sign In</a>.</div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import axios from 'axios'
import $ from 'jquery'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
// import { useStorage } from "vue3-storage";
// import { useToast } from "vue-toastification";
// const toast = useToast();
// const storage = useStorage()

// const reqProcessing = ref(false)
// Add form validation
const resetPass_ = ref({
    email:''
})

const rules = {
    email:{ required, email }
}
const v$ = useVuelidate(rules, resetPass_)
function resetPassword(){
    v$.value.$touch()
    if (!v$.value.$error) {
        axios.post('resetPassword',resetPass_.value).then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
}

function openModal(id){
    $(id).modal('show')
}
</script>
<style scoped>  
    .form-control{
      height: 45px;
      background-color:#f5faff;
    }
    .modal-title{
      font-size:22px;
    }
    .btn-common{
        padding-top: 0.585rem;
        padding-bottom: 0.585rem;
    }
    .modal .form-label, .modal .form-check label{
      font-size: 16px;
    }
    @media (max-width: 575px)
    {
      .modal{
        --bs-modal-margin: 0px;
      }
    }
</style>